//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios'
import type { SwaggerResponse } from './config'
import { Http } from './httpRequest'
//@ts-ignore
import qs from 'qs'
import type {
  MediaStorageGetListQueryParams,
  LiveStreamsGetAllQueryParams,
  BrandsGetBrandsQueryParams,
  SequencePollValidateExternalUrlQueryParams,
  SequencePollSaveUpdateQueryParams,
  MediaStorageUploadContentQueryParams,
  AdminPlatformActionsReloadExternalUrlValidationsQueryParams,
  SequencePollGetTemplatesQueryParams,
  SequencePollGetAllQueryParams,
  SequencePollGetAvailableOwnersQueryParams,
  SequencePollGetAvailableApprovalStatusesQueryParams,
  ProxyUnsplashGetPhotosQueryParams,
  NotificationsGetAllQueryParams,
  LiveStreamsGetAvailableOwnersQueryParams,
  ExperienceRevisionsGetForExperienceQueryParams,
  DashboardGetExperienceStatisticsSummaryQueryParams,
  DashboardGetOrganizationDataCreatorChartsRealtimeQueryParams,
  DashboardGetOrganizationDataCreatorChartsByRangeQueryParams,
  DashboardGetCreatorActivitySummaryQueryParams,
  DashboardGetOrganizationDataChartsRealtimeQueryParams,
  DashboardGetOrganizationDataChartsByRangeQueryParams,
  DashboardGetAvailableBrandsQueryParams,
  BrandsGetAssignableBrandsQueryParams,
  AdminOrganizationFindAllQueryParams,
  MediaStorageItemCreateRequest,
  MediaStorageItemDataFull,
  LiveStreamCreateRequest,
  LiveStreamListData,
  LiveStreamDestinationCreateRequest,
  LiveStreamDestinationData,
  BrandUpdateRequest,
  BillingDetailsData,
  ExternalAccessAuthorizeRequest,
  ExternalUserAuthorizeResponse,
  UserData,
  SequencePollSetFeaturedListImageRequest,
  ExperiencePublisherShareMediaAssetsDataRequest,
  ExperiencePublisherShareMediaAssetsData,
  ExperienceFollowerShareMediaAssetsDataRequest,
  ExperienceFollowerShareMediaAssetsData,
  ExperiencePublisherShareRequest,
  ExperiencePublisherShareValidationResult,
  ValidateExternalUrlRequest,
  ValidateExternalUrlResponse,
  SequencePollUpdateRequest,
  SequencePollPreviewData,
  OrganizationOriginUpdateRequest,
  OrganizationData,
  OrganizationInviteRequest,
  OrganizationInviteResponse,
  GuidelinesData,
  MediaStorageItemUpdateLabelRequest,
  LiveStreamUpdateRequest,
  SendMessageRequest,
  RequestDetailsRequest,
  ReplyMessageRequest,
  ReactMessageRequest,
  JoinConversationRequest,
  InstagramExchangeCodeRequest,
  InstagramGetMediaRequest,
  InstagramMediaResponse,
  ExperienceLinkRequest,
  ExperienceRevisionsSubmitMemberRevisionRequest,
  ExperienceRevisionsSubmitCreatorCommentRequest,
  CreatorRequiresApprovalRequest,
  CreatorProfileData,
  SubscribeRequest,
  SelectSubscriptionTierTypeRequest,
  AdminOrganizationDetailsProjection,
  AdminOrganizationReassignOwnerRequest,
  AdminOrganizationLinkOriginRequest,
  OrganizationPendingInvitation,
  OrganizationSwitchable,
  UIPermissions,
  UIConstants,
  SequencePollOverlayData,
  NumberedListSequencePollListData,
  SequencePollExperienceEditorConfiguration,
  ExperienceAvailableOwnerData,
  PairMediaStorageItemObjectAggregatedTypeString,
  ExperienceAvailableApprovalStatus,
  OrganizationOriginDataObject,
  OrganizationUsageData,
  OrganizationMemberData,
  OrganizationFonts,
  OrganizationSettingsBooleanResponse,
  BOUserNotificationData,
  NumberedListMediaStorageItemDataFull,
  ChunkedDataSummary,
  NumberedListLiveStreamListData,
  LiveStreamSingleData,
  LiveStreamStatusResponse,
  LiveStreamRecordingsResponse,
  LiveStreamDestinations,
  LiveStreamStreamingCredentials,
  DashboardCharts,
  LiveStreamAvailableOwnerData,
  LiveChatHistory,
  LiveChatUserProfileDataObject,
  LiveChatReactions,
  InstagramProfile,
  ShareableUserData,
  SharedExperience,
  NumberedListExperienceRevisionData,
  ExperienceFollowerShareData,
  ExperienceCollectionData,
  ExperienceStatisticsSummary,
  CreatorActivitySummary,
  CreatorListData,
  BrandData,
  NumberedListBrandData,
  BillingSubscriptionDetails,
  BillingPricingResponse,
  SubscriptionTierLimits,
  BillingCardsData,
  BillingCardsSetupData,
  NumberedListAdminOrganizationListProjection,
  AdminOrganizationDetailsData,
} from './types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig,
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach(key => {
    const re = new RegExp(`{${key}}`, 'i')
    path = path.replace(re, obj[key])
  })

  return path
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData()

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value)
  })

  return formData
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody)
}

export const OrganizationMemberSettingsIsNotificationsExperienceReviewSubmissionEnabled =
  (
    organizationId: string,
    userId: string,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<OrganizationSettingsBooleanResponse>> => {
    return Http.getRequest(
      template(
        OrganizationMemberSettingsIsNotificationsExperienceReviewSubmissionEnabled.key,
        { organizationId, userId },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    )
  }

/** Key is end point string without base url */
OrganizationMemberSettingsIsNotificationsExperienceReviewSubmissionEnabled.key =
  '/api/bo/organizationMemberSettings/{organizationId}/{userId}/isNotificationsExperienceReviewSubmissionEnabled'

export const OrganizationMemberSettingsSetNotificationsExperienceReviewSubmissionEnabled =
  (
    organizationId: string,
    userId: string,
    value: boolean,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<{ [x: string]: string }>> => {
    return Http.postRequest(
      template(
        OrganizationMemberSettingsSetNotificationsExperienceReviewSubmissionEnabled.key,
        { organizationId, userId, value },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    )
  }

/** Key is end point string without base url */
OrganizationMemberSettingsSetNotificationsExperienceReviewSubmissionEnabled.key =
  '/api/bo/organizationMemberSettings/{organizationId}/{userId}/setNotificationsExperienceReviewSubmissionEnabled/{value}'

export const adminMessageBrokerReplay = (
  messageId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminMessageBrokerReplay.key, { messageId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminMessageBrokerReplay.key = '/api/admin/messagebroker/replay/{messageId}'

export const adminOrganizationAddAdmin = (
  id: string,
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationAddAdmin.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationAddAdmin.key =
  '/api/admin/organizations/{id}/administrators/add'

export const adminOrganizationAddCreator = (
  id: string,
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationAddCreator.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationAddCreator.key = '/api/admin/organizations/{id}/creators/add'

export const adminOrganizationAddMember = (
  id: string,
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationAddMember.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationAddMember.key = '/api/admin/organizations/{id}/members/add'

export const adminOrganizationFindAll = (
  queryParams: AdminOrganizationFindAllQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListAdminOrganizationListProjection>> => {
  return Http.getRequest(
    adminOrganizationFindAll.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationFindAll.key = '/api/admin/organizations'

export const adminOrganizationFindSingle = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AdminOrganizationDetailsData>> => {
  return Http.getRequest(
    template(adminOrganizationFindSingle.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationFindSingle.key = '/api/admin/organizations/{id}'

export const adminOrganizationLinkOrigin = (
  id: string,
  requestBody: AdminOrganizationLinkOriginRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationLinkOrigin.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationLinkOrigin.key = '/api/admin/organizations/{id}/origins/link'

export const adminOrganizationOwnerCreatorProfileGet = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CreatorProfileData>> => {
  return Http.getRequest(
    template(adminOrganizationOwnerCreatorProfileGet.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationOwnerCreatorProfileGet.key =
  '/api/admin/organizations/{id}/ownerCreatorProfile'

export const adminOrganizationOwnerCreatorProfileUpdate = (
  id: string,
  requestBody: CreatorProfileData,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ [x: string]: { [x in string | number]: any } }>
> => {
  return Http.postRequest(
    template(adminOrganizationOwnerCreatorProfileUpdate.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationOwnerCreatorProfileUpdate.key =
  '/api/admin/organizations/{id}/ownerCreatorProfilerofile'

export const adminOrganizationReassignOwner = (
  id: string,
  requestBody: AdminOrganizationReassignOwnerRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationReassignOwner.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationReassignOwner.key =
  '/api/admin/organizations/{id}/reassignOwner'

export const adminOrganizationRemoveUser = (
  id: string,
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationRemoveUser.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationRemoveUser.key = '/api/admin/organizations/{id}/users/remove'

export const adminOrganizationUnlinkOrigin = (
  id: string,
  requestBody: AdminOrganizationLinkOriginRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationUnlinkOrigin.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationUnlinkOrigin.key =
  '/api/admin/organizations/{id}/origins/unlink'

export const adminOrganizationUpdateSingle = (
  id: string,
  requestBody: AdminOrganizationDetailsProjection,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminOrganizationUpdateSingle.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminOrganizationUpdateSingle.key = '/api/admin/organizations/{id}'

export const adminPlatformActionSendUserNotificationsTrigger10MinsLiveStreamReminder =
  (
    liveStreamId: string,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<{ [x: string]: string }>> => {
    return Http.postRequest(
      template(
        adminPlatformActionSendUserNotificationsTrigger10MinsLiveStreamReminder.key,
        { liveStreamId },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    )
  }

/** Key is end point string without base url */
adminPlatformActionSendUserNotificationsTrigger10MinsLiveStreamReminder.key =
  '/api/admin/platform/actions/endUserNotificationsTrigger10MinsLiveStreamReminder/{liveStreamId}'

export const adminPlatformActionSendUserNotificationsTrigger24HourLiveStreamReminder =
  (
    liveStreamId: string,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<{ [x: string]: string }>> => {
    return Http.postRequest(
      template(
        adminPlatformActionSendUserNotificationsTrigger24HourLiveStreamReminder.key,
        { liveStreamId },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    )
  }

/** Key is end point string without base url */
adminPlatformActionSendUserNotificationsTrigger24HourLiveStreamReminder.key =
  '/api/admin/platform/actions/endUserNotificationsTrigger24HourLiveStreamReminder/{liveStreamId}'

export const adminPlatformActionsCleanupMuxCloudAssets = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsCleanupMuxCloudAssets.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsCleanupMuxCloudAssets.key =
  '/api/admin/platform/actions/cleanupMuxCloudAssets'

export const adminPlatformActionsCleanupOldAggregates = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsCleanupOldAggregates.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsCleanupOldAggregates.key =
  '/api/admin/platform/actions/cleanupOldAggregates'

export const adminPlatformActionsDeduplicateStatisticsAggregates = (
  productId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsDeduplicateStatisticsAggregates.key, {
      productId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsDeduplicateStatisticsAggregates.key =
  '/api/admin/platform/actions/deduplicateStatisticsAggregates/{productId}'

export const adminPlatformActionsDeleteProtectLivestreamRecordingPeriods = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsDeleteProtectLivestreamRecordingPeriods.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsDeleteProtectLivestreamRecordingPeriods.key =
  '/api/admin/platform/actions/deleteProtectLivestreamRecordingPeriods'

export const adminPlatformActionsEvictTransientCaches = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsEvictTransientCaches.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsEvictTransientCaches.key =
  '/api/admin/platform/actions/evictTransientCaches'

export const adminPlatformActionsNeutrinoIpInfoLookupDailyBatch = (
  limit: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsNeutrinoIpInfoLookupDailyBatch.key, { limit }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsNeutrinoIpInfoLookupDailyBatch.key =
  '/api/admin/platform/actions/neutrinoIpInfoLookupDailyBatch/{limit}'

export const adminPlatformActionsReaggregateEmailTrackerEvents = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsReaggregateEmailTrackerEvents.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsReaggregateEmailTrackerEvents.key =
  '/api/admin/platform/actions/reaggregateEmailTrackerEvents'

export const adminPlatformActionsReaggregateSales = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsReaggregateSales.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsReaggregateSales.key =
  '/api/admin/platform/actions/reaggregateSales'

export const adminPlatformActionsRebuildStatisticsAggregates = (
  productId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsRebuildStatisticsAggregates.key, {
      productId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsRebuildStatisticsAggregates.key =
  '/api/admin/platform/actions/rebuildStatisticsAggregates/{productId}'

export const adminPlatformActionsRebuildStatisticsAggregatesAll = (
  limit: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsRebuildStatisticsAggregatesAll.key, { limit }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsRebuildStatisticsAggregatesAll.key =
  '/api/admin/platform/actions/rebuildStatisticsAggregatesAll/{limit}'

export const adminPlatformActionsReloadExternalUrl = (
  urlBase64: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsReloadExternalUrl.key, { urlBase64 }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsReloadExternalUrl.key =
  '/api/admin/platform/actions/reloadExternalUrlValidation/{urlBase64}'

export const adminPlatformActionsReloadExternalUrlValidations = (
  queryParams?: AdminPlatformActionsReloadExternalUrlValidationsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsReloadExternalUrlValidations.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsReloadExternalUrlValidations.key =
  '/api/admin/platform/actions/reloadExternalUrlValidations'

export const adminPlatformActionsStressTest = (
  param: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsStressTest.key, { param }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsStressTest.key =
  '/api/admin/platform/actions/stressTest/{param}'

export const adminPlatformActionsTriggerAwinTransactionFillingMissingValues = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    adminPlatformActionsTriggerAwinTransactionFillingMissingValues.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsTriggerAwinTransactionFillingMissingValues.key =
  '/api/admin/platform/actions/triggerAwinTransactionFillingMissingValues'

export const adminPlatformActionsTriggerExperienceWarmup = (
  experienceId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsTriggerExperienceWarmup.key, { experienceId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsTriggerExperienceWarmup.key =
  '/api/admin/platform/actions/triggerExperienceWarmup/{experienceId}'

export const adminPlatformActionsTriggerMediaStorageItemVideoTranscoding = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(adminPlatformActionsTriggerMediaStorageItemVideoTranscoding.key, {
      id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
adminPlatformActionsTriggerMediaStorageItemVideoTranscoding.key =
  '/api/admin/platform/actions/triggerMediaStorageItemVideoTranscoding/{id}'

export const adminPlatformActionsTriggerMediaStorageItemVideoTranscodingForMissing =
  (
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<{ [x: string]: string }>> => {
    return Http.postRequest(
      adminPlatformActionsTriggerMediaStorageItemVideoTranscodingForMissing.key,
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    )
  }

/** Key is end point string without base url */
adminPlatformActionsTriggerMediaStorageItemVideoTranscodingForMissing.key =
  '/api/admin/platform/actions/triggerMediaStorageItemVideoTranscodingForMissing'

export const adminPlatformActionsTriggerVideoTranscodingMetadataRefetchAndProcessing =
  (
    pipelineId: string,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<{ [x: string]: string }>> => {
    return Http.postRequest(
      template(
        adminPlatformActionsTriggerVideoTranscodingMetadataRefetchAndProcessing.key,
        { pipelineId },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    )
  }

/** Key is end point string without base url */
adminPlatformActionsTriggerVideoTranscodingMetadataRefetchAndProcessing.key =
  '/api/admin/platform/actions/triggerVideoTranscodingMetadataRefetchAndProcessing/{pipelineId}'

export const autopilotLiveFocus = (
  experienceId: string,
  cardId: string,
  syncWithLatency: boolean,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(autopilotLiveFocus.key, { experienceId, cardId, syncWithLatency }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
autopilotLiveFocus.key =
  '/api/bo/autopilot/live/focus/{experienceId}/{cardId}/{syncWithLatency}'

export const billingCancelSubscription = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    billingCancelSubscription.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride),
  )
}

/** Key is end point string without base url */
billingCancelSubscription.key = '/api/bo/billing/subscription/cancel'

export const billingConfirmSubscription = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.putRequest(
    billingConfirmSubscription.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingConfirmSubscription.key = '/api/bo/billing/subscription/confirm'

export const billingGetCards = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingCardsData>> => {
  return Http.getRequest(
    billingGetCards.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetCards.key = '/api/bo/billing/cards'

export const billingGetCardsSetupData = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingCardsSetupData>> => {
  return Http.getRequest(
    billingGetCardsSetupData.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetCardsSetupData.key = '/api/bo/billing/cards/setup'

export const billingGetDetails = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingDetailsData>> => {
  return Http.getRequest(
    billingGetDetails.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetDetails.key = '/api/bo/billing/details'

export const billingGetPricing = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingPricingResponse>> => {
  return Http.getRequest(
    billingGetPricing.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetPricing.key = '/api/bo/billing/pricing'

export const billingGetSubscriptionDetails = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingSubscriptionDetails>> => {
  return Http.getRequest(
    billingGetSubscriptionDetails.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetSubscriptionDetails.key = '/api/bo/billing/subscription'

export const billingGetSubscriptionTierLimits = (
  tier:
    | 'PendingSelection'
    | 'BusinessStandard'
    | 'BusinessCreator'
    | 'BusinessEnterprise'
    | 'CreatorStandard',
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SubscriptionTierLimits>> => {
  return Http.getRequest(
    template(billingGetSubscriptionTierLimits.key, { tier }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingGetSubscriptionTierLimits.key = '/api/bo/billing/limits/{tier}'

export const billingRemoveCard = (
  cardId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(billingRemoveCard.key, { cardId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingRemoveCard.key = '/api/bo/billing/cards/{cardId}'

export const billingSelectSubscriptionTierType = (
  requestBody: SelectSubscriptionTierTypeRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    billingSelectSubscriptionTierType.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingSelectSubscriptionTierType.key = '/api/bo/billing/subscription/type'

export const billingSetCardActive = (
  cardId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(billingSetCardActive.key, { cardId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingSetCardActive.key = '/api/bo/billing/cards/{cardId}/setActive'

export const billingSubscribe = (
  requestBody: SubscribeRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    billingSubscribe.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingSubscribe.key = '/api/bo/billing/subscription'

export const billingUpdateDetails = (
  requestBody: BillingDetailsData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.putRequest(
    billingUpdateDetails.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
billingUpdateDetails.key = '/api/bo/billing/details'

export const brandsCreateBrand = (
  requestBody: BrandUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.putRequest(
    brandsCreateBrand.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsCreateBrand.key = '/api/bo/brands'

export const brandsDeleteBrand = (
  brandId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(brandsDeleteBrand.key, { brandId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsDeleteBrand.key = '/api/bo/brands/{brandId}'

export const brandsGetAssignableBrands = (
  queryParams?: BrandsGetAssignableBrandsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListBrandData>> => {
  return Http.getRequest(
    brandsGetAssignableBrands.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsGetAssignableBrands.key = '/api/bo/brands/assignable'

export const brandsGetBrand = (
  brandId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BrandData>> => {
  return Http.getRequest(
    template(brandsGetBrand.key, { brandId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsGetBrand.key = '/api/bo/brands/{brandId}'

export const brandsGetBrands = (
  queryParams?: BrandsGetBrandsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListBrandData>> => {
  return Http.getRequest(
    brandsGetBrands.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsGetBrands.key = '/api/bo/brands'

export const brandsUpdateBrand = (
  brandId: string,
  requestBody: BrandUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(brandsUpdateBrand.key, { brandId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
brandsUpdateBrand.key = '/api/bo/brands/{brandId}'

export const creatorProfileGet = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CreatorProfileData>> => {
  return Http.getRequest(
    creatorProfileGet.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
creatorProfileGet.key = '/api/bo/creators/profile'

export const creatorProfileUpdate = (
  requestBody: CreatorProfileData,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ [x: string]: { [x in string | number]: any } }>
> => {
  return Http.postRequest(
    creatorProfileUpdate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
creatorProfileUpdate.key = '/api/bo/creators/profile'

export const creatorsSetRequiresApproval = (
  requestBody: CreatorRequiresApprovalRequest,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ [x: string]: { [x in string | number]: any } }>
> => {
  return Http.postRequest(
    creatorsSetRequiresApproval.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
creatorsSetRequiresApproval.key = '/api/bo/creators'

export const dashboardGetAvailableBrands = (
  queryParams?: DashboardGetAvailableBrandsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BrandData[]>> => {
  return Http.getRequest(
    dashboardGetAvailableBrands.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetAvailableBrands.key = '/api/bo/dashboard/availableBrands'

export const dashboardGetAvailableCreators = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CreatorListData[]>> => {
  return Http.getRequest(
    dashboardGetAvailableCreators.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetAvailableCreators.key = '/api/bo/dashboard/availableCreators'

export const dashboardGetCreatorActivitySummary = (
  queryParams: DashboardGetCreatorActivitySummaryQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CreatorActivitySummary>> => {
  return Http.getRequest(
    dashboardGetCreatorActivitySummary.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetCreatorActivitySummary.key =
  '/api/bo/dashboard/creatorActivitySummary'

export const dashboardGetExperienceStatisticsSummary = (
  queryParams: DashboardGetExperienceStatisticsSummaryQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceStatisticsSummary>> => {
  return Http.getRequest(
    dashboardGetExperienceStatisticsSummary.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetExperienceStatisticsSummary.key =
  '/api/bo/dashboard/experienceStatistics'

export const dashboardGetOrganizationDataChartsByRange = (
  queryParams: DashboardGetOrganizationDataChartsByRangeQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardCharts>> => {
  return Http.getRequest(
    dashboardGetOrganizationDataChartsByRange.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetOrganizationDataChartsByRange.key =
  '/api/bo/dashboard/chartsByRange'

export const dashboardGetOrganizationDataChartsRealtime = (
  queryParams?: DashboardGetOrganizationDataChartsRealtimeQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardCharts>> => {
  return Http.getRequest(
    dashboardGetOrganizationDataChartsRealtime.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetOrganizationDataChartsRealtime.key =
  '/api/bo/dashboard/chartsRealtime'

export const dashboardGetOrganizationDataCreatorChartsByRange = (
  queryParams: DashboardGetOrganizationDataCreatorChartsByRangeQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardCharts>> => {
  return Http.getRequest(
    dashboardGetOrganizationDataCreatorChartsByRange.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetOrganizationDataCreatorChartsByRange.key =
  '/api/bo/dashboard/creatorChartsByRange'

export const dashboardGetOrganizationDataCreatorChartsRealtime = (
  queryParams?: DashboardGetOrganizationDataCreatorChartsRealtimeQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardCharts>> => {
  return Http.getRequest(
    dashboardGetOrganizationDataCreatorChartsRealtime.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
dashboardGetOrganizationDataCreatorChartsRealtime.key =
  '/api/bo/dashboard/creatorChartsRealtime'

export const experienceCollectionGetOrganizationOverview = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceCollectionData[]>> => {
  return Http.getRequest(
    experienceCollectionGetOrganizationOverview.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceCollectionGetOrganizationOverview.key =
  '/api/bo/experiencecollections'

export const experienceFollowerShareDoShare = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(experienceFollowerShareDoShare.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceFollowerShareDoShare.key =
  '/api/bo/experiences/followershare/{id}/share'

export const experienceFollowerShareGetData = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceFollowerShareData>> => {
  return Http.getRequest(
    template(experienceFollowerShareGetData.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceFollowerShareGetData.key =
  '/api/bo/experiences/followershare/{id}/state'

export const experienceLinksDoUnshare = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(experienceLinksDoUnshare.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT1, configOverride),
  )
}

/** Key is end point string without base url */
experienceLinksDoUnshare.key = '/api/bo/experiences/shares/unshare/{id}'

export const experienceRevisionsGetForExperience = (
  experienceId: string,
  queryParams: ExperienceRevisionsGetForExperienceQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListExperienceRevisionData>> => {
  return Http.getRequest(
    template(experienceRevisionsGetForExperience.key, { experienceId }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceRevisionsGetForExperience.key =
  '/api/bo/experiences/revisions/{experienceId}'

export const experienceRevisionsSubmitCreatorComment = (
  experienceId: string,
  requestBody: ExperienceRevisionsSubmitCreatorCommentRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(experienceRevisionsSubmitCreatorComment.key, { experienceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceRevisionsSubmitCreatorComment.key =
  '/api/bo/experiences/revisions/{experienceId}/creatorComment'

export const experienceRevisionsSubmitForReview = (
  experienceId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(experienceRevisionsSubmitForReview.key, { experienceId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceRevisionsSubmitForReview.key =
  '/api/bo/experiences/revisions/{experienceId}/submitForReview'

export const experienceRevisionsSubmitMemberRevision = (
  experienceId: string,
  requestBody: ExperienceRevisionsSubmitMemberRevisionRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(experienceRevisionsSubmitMemberRevision.key, { experienceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceRevisionsSubmitMemberRevision.key =
  '/api/bo/experiences/revisions/{experienceId}/memberRevision'

export const experienceSharesDoShare = (
  requestBody: ExperienceLinkRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    experienceSharesDoShare.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT1, configOverride),
  )
}

/** Key is end point string without base url */
experienceSharesDoShare.key = '/api/bo/experiences/shares/share'

export const experienceSharesGetEstablishedShares = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SharedExperience[]>> => {
  return Http.getRequest(
    experienceSharesGetEstablishedShares.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceSharesGetEstablishedShares.key =
  '/api/bo/experiences/shares/experienceShares'

export const experienceSharesGetShareableUsers = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShareableUserData[]>> => {
  return Http.getRequest(
    experienceSharesGetShareableUsers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
experienceSharesGetShareableUsers.key =
  '/api/bo/experiences/shares/shareableUsers'

export const externalAccessAuthorize = (
  requestBody: ExternalAccessAuthorizeRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExternalUserAuthorizeResponse>> => {
  return Http.postRequest(
    externalAccessAuthorize.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
externalAccessAuthorize.key = '/api/external/authorize'

export const instagramGetMedia = (
  requestBody: InstagramGetMediaRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InstagramMediaResponse>> => {
  return Http.postRequest(
    instagramGetMedia.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
instagramGetMedia.key = '/api/bo/integration/instagram/media'

export const instagramGetProfile = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InstagramProfile>> => {
  return Http.getRequest(
    instagramGetProfile.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
instagramGetProfile.key = '/api/bo/integration/instagram/profile'

export const instagramRevokeAuthorization = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    instagramRevokeAuthorization.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
instagramRevokeAuthorization.key =
  '/api/bo/integration/instagram/revokeAuthorization'

export const instagramSubmitAuthorizationCode = (
  requestBody: InstagramExchangeCodeRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string>> => {
  return Http.postRequest(
    instagramSubmitAuthorizationCode.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
instagramSubmitAuthorizationCode.key =
  '/api/bo/integration/instagram/submitAuthorizationCode'

export const liveChatBlockUser = (
  organizationId: string,
  referenceId: string,
  liveChatUserId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatBlockUser.key, {
      organizationId,
      referenceId,
      liveChatUserId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatBlockUser.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/users/{liveChatUserId}/block'

export const liveChatGetAvailableReactions = (
  organizationId: string,
  referenceId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveChatReactions>> => {
  return Http.getRequest(
    template(liveChatGetAvailableReactions.key, {
      organizationId,
      referenceId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatGetAvailableReactions.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/availableReactions'

export const liveChatGetCurrentUser = (
  organizationId: string,
  referenceId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveChatUserProfileDataObject>> => {
  return Http.getRequest(
    template(liveChatGetCurrentUser.key, { organizationId, referenceId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatGetCurrentUser.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/currentUser'

export const liveChatGetHistory = (
  organizationId: string,
  referenceId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveChatHistory>> => {
  return Http.getRequest(
    template(liveChatGetHistory.key, { organizationId, referenceId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatGetHistory.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/history'

export const liveChatJoinConversation = (
  organizationId: string,
  referenceId: string,
  requestBody: JoinConversationRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatJoinConversation.key, { organizationId, referenceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatJoinConversation.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/join'

export const liveChatMessageDelete = (
  organizationId: string,
  referenceId: string,
  messageId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatMessageDelete.key, {
      organizationId,
      referenceId,
      messageId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatMessageDelete.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/messages/{messageId}/delete'

export const liveChatMessagePin = (
  organizationId: string,
  referenceId: string,
  messageId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatMessagePin.key, {
      organizationId,
      referenceId,
      messageId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatMessagePin.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/messages/{messageId}/pin'

export const liveChatMessageUnpin = (
  organizationId: string,
  referenceId: string,
  messageId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatMessageUnpin.key, {
      organizationId,
      referenceId,
      messageId,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatMessageUnpin.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/messages/{messageId}/unpin'

export const liveChatReactMessage = (
  organizationId: string,
  referenceId: string,
  requestBody: ReactMessageRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatReactMessage.key, { organizationId, referenceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatReactMessage.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/react'

export const liveChatReplyMessage = (
  organizationId: string,
  referenceId: string,
  requestBody: ReplyMessageRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatReplyMessage.key, { organizationId, referenceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatReplyMessage.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/reply'

export const liveChatRequestDetails = (
  organizationId: string,
  referenceId: string,
  requestBody: RequestDetailsRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatRequestDetails.key, { organizationId, referenceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatRequestDetails.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/requestDetails'

export const liveChatSendMessage = (
  organizationId: string,
  referenceId: string,
  requestBody: SendMessageRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveChatSendMessage.key, { organizationId, referenceId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveChatSendMessage.key =
  '/api/bo/livechats/{organizationId}/{referenceId}/send'

export const liveStreamChangeAccessType = (
  id: string,
  accessType: 'Standard' | 'Extended',
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveStreamChangeAccessType.key, { id, accessType }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamChangeAccessType.key =
  '/api/bo/livestreams/{id}/changeAccessType/{accessType}'

export const liveStreamsComplete = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveStreamsComplete.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsComplete.key = '/api/bo/livestreams/{id}/complete'

export const liveStreamsConnect = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveStreamsConnect.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsConnect.key = '/api/bo/livestreams/{id}/connect'

export const liveStreamsCreate = (
  requestBody: LiveStreamCreateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamListData>> => {
  return Http.putRequest(
    liveStreamsCreate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsCreate.key = '/api/bo/livestreams'

export const liveStreamsCreateDestination = (
  id: string,
  requestBody: LiveStreamDestinationCreateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamDestinationData>> => {
  return Http.putRequest(
    template(liveStreamsCreateDestination.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsCreateDestination.key = '/api/bo/livestreams/{id}/destinations'

export const liveStreamsDelete = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(liveStreamsDelete.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsDelete.key = '/api/bo/livestreams/{id}'

export const liveStreamsDeleteDestination = (
  id: string,
  destinationId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(liveStreamsDeleteDestination.key, { id, destinationId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsDeleteDestination.key =
  '/api/bo/livestreams/{id}/destinations/{destinationId}'

export const liveStreamsDisconnect = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(liveStreamsDisconnect.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsDisconnect.key = '/api/bo/livestreams/{id}/disconnect'

export const liveStreamsGetAll = (
  queryParams: LiveStreamsGetAllQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListLiveStreamListData>> => {
  return Http.getRequest(
    liveStreamsGetAll.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetAll.key = '/api/bo/livestreams'

export const liveStreamsGetAvailableOwners = (
  queryParams: LiveStreamsGetAvailableOwnersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamAvailableOwnerData[]>> => {
  return Http.getRequest(
    liveStreamsGetAvailableOwners.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetAvailableOwners.key = '/api/bo/livestreams/availableOwners'

export const liveStreamsGetChartsData = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardCharts>> => {
  return Http.getRequest(
    template(liveStreamsGetChartsData.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetChartsData.key = '/api/bo/livestreams/{id}/charts'

export const liveStreamsGetCredentials = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamStreamingCredentials>> => {
  return Http.getRequest(
    template(liveStreamsGetCredentials.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetCredentials.key = '/api/bo/livestreams/{id}/credentials'

export const liveStreamsGetDestinations = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamDestinations>> => {
  return Http.getRequest(
    template(liveStreamsGetDestinations.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetDestinations.key = '/api/bo/livestreams/{id}/destinations'

export const liveStreamsGetRecordings = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamRecordingsResponse>> => {
  return Http.getRequest(
    template(liveStreamsGetRecordings.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetRecordings.key = '/api/bo/livestreams/{id}/recordings'

export const liveStreamsGetSingle = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamSingleData>> => {
  return Http.getRequest(
    template(liveStreamsGetSingle.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetSingle.key = '/api/bo/livestreams/{id}'

export const liveStreamsGetStreamStatus = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamStatusResponse>> => {
  return Http.getRequest(
    template(liveStreamsGetStreamStatus.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsGetStreamStatus.key = '/api/bo/livestreams/{id}/status'

export const liveStreamsUpdate = (
  id: string,
  requestBody: LiveStreamUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<LiveStreamListData>> => {
  return Http.postRequest(
    template(liveStreamsUpdate.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
liveStreamsUpdate.key = '/api/bo/livestreams/{id}'

export const mediaStorageChunked = (
  id: string,
  size: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ChunkedDataSummary>> => {
  return Http.getRequest(
    template(mediaStorageChunked.key, { id, size }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageChunked.key = '/api/bo/mediastorage/{id}/chunked/{size}'

export const mediaStorageChunkedUpload = (
  id: string,
  size: number,
  chunk: number,
  requestBody: {
    /**
     *
     * - Format: binary
     */
    file: string
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(mediaStorageChunkedUpload.key, { id, size, chunk }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageChunkedUpload.key =
  '/api/bo/mediastorage/{id}/chunked/{size}/{chunk}'

export const mediaStorageCreateItem = (
  requestBody: MediaStorageItemCreateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemDataFull>> => {
  return Http.putRequest(
    mediaStorageCreateItem.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageCreateItem.key = '/api/bo/mediastorage'

export const mediaStorageDelete = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(mediaStorageDelete.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageDelete.key = '/api/bo/mediastorage/{id}'

export const mediaStorageGetList = (
  queryParams?: MediaStorageGetListQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListMediaStorageItemDataFull>> => {
  return Http.getRequest(
    mediaStorageGetList.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageGetList.key = '/api/bo/mediastorage'

export const mediaStorageGetSingle = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemDataFull>> => {
  return Http.getRequest(
    template(mediaStorageGetSingle.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageGetSingle.key = '/api/bo/mediastorage/{id}'

export const mediaStorageProxyExternalImage = (
  url: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string>> => {
  return Http.getRequest(
    template(mediaStorageProxyExternalImage.key, { url }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageProxyExternalImage.key = '/api/bo/mediastorage/proxy/{url}'

export const mediaStorageReplicate = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemDataFull>> => {
  return Http.postRequest(
    template(mediaStorageReplicate.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageReplicate.key = '/api/bo/mediastorage/{id}/replicate'

export const mediaStorageSetAvailableForCreators = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemDataFull>> => {
  return Http.postRequest(
    template(mediaStorageSetAvailableForCreators.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageSetAvailableForCreators.key =
  '/api/bo/mediastorage/{id}/setAvailableForCreators'

export const mediaStorageSetUnavailableForCreators = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemDataFull>> => {
  return Http.postRequest(
    template(mediaStorageSetUnavailableForCreators.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageSetUnavailableForCreators.key =
  '/api/bo/mediastorage/{id}/setUnavailableForCreators'

export const mediaStorageUpdateLabel = (
  id: string,
  requestBody: MediaStorageItemUpdateLabelRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemDataFull>> => {
  return Http.postRequest(
    template(mediaStorageUpdateLabel.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageUpdateLabel.key = '/api/bo/mediastorage/{id}/label'

export const mediaStorageUploadContent = (
  id: string,
  requestBody: {
    /**
     *
     * - Format: binary
     */
    file: string
  },
  queryParams: MediaStorageUploadContentQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MediaStorageItemDataFull>> => {
  return Http.postRequest(
    template(mediaStorageUploadContent.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
mediaStorageUploadContent.key = '/api/bo/mediastorage/{id}'

export const notificationsAcknowledge = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(notificationsAcknowledge.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
notificationsAcknowledge.key = '/api/bo/notifications/{id}/acknowledge'

export const notificationsGetAll = (
  queryParams: NotificationsGetAllQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BOUserNotificationData[]>> => {
  return Http.getRequest(
    notificationsGetAll.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
notificationsGetAll.key = '/api/bo/notifications'

export const organizationAssignMemberAdmin = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationAssignMemberAdmin.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationAssignMemberAdmin.key =
  '/api/bo/organizations/members/{id}/setAdmin'

export const organizationCancelPendingInvitation = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationCancelPendingInvitation.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationCancelPendingInvitation.key =
  '/api/bo/organizations/invitations/{id}/cancel'

export const organizationClaimShopifyShop = (
  shop: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationClaimShopifyShop.key, { shop }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationClaimShopifyShop.key = '/api/bo/organizations/shopify/claim/{shop}'

export const organizationGetCreators = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationMemberData[]>> => {
  return Http.getRequest(
    organizationGetCreators.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetCreators.key = '/api/bo/organizations/creators'

export const organizationGetFonts = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationFonts>> => {
  return Http.getRequest(
    organizationGetFonts.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetFonts.key = '/api/bo/organizations/availableFonts'

export const organizationGetMembers = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationMemberData[]>> => {
  return Http.getRequest(
    organizationGetMembers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetMembers.key = '/api/bo/organizations/members'

export const organizationGetPendingInvitations = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationPendingInvitation[]>> => {
  return Http.getRequest(
    organizationGetPendingInvitations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetPendingInvitations.key = '/api/bo/organizations/invitations'

export const organizationGetUsage = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationUsageData>> => {
  return Http.getRequest(
    organizationGetUsage.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGetUsage.key = '/api/bo/organizations/usage'

export const organizationGuidelinesGet = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<GuidelinesData>> => {
  return Http.getRequest(
    organizationGuidelinesGet.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGuidelinesGet.key = '/api/bo/organizations/guidelines'

export const organizationGuidelinesUpdate = (
  requestBody: GuidelinesData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    organizationGuidelinesUpdate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationGuidelinesUpdate.key = '/api/bo/organizations/guidelines'

export const organizationInviteCreator = (
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationInviteResponse>> => {
  return Http.postRequest(
    organizationInviteCreator.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationInviteCreator.key = '/api/bo/organizations/creators/invite'

export const organizationInviteMember = (
  requestBody: OrganizationInviteRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationInviteResponse>> => {
  return Http.postRequest(
    organizationInviteMember.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationInviteMember.key = '/api/bo/organizations/members/invite'

export const organizationRemoveMember = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationRemoveMember.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationRemoveMember.key = '/api/bo/organizations/members/{id}/remove'

export const organizationUnassignMemberAdmin = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(organizationUnassignMemberAdmin.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationUnassignMemberAdmin.key =
  '/api/bo/organizations/members/{id}/unsetAdmin'

export const organizationUpdateProfile = (
  requestBody: OrganizationData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    organizationUpdateProfile.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
organizationUpdateProfile.key = '/api/bo/organizations/profile'

export const originsDeleteSingle = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.deleteRequest(
    template(originsDeleteSingle.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
originsDeleteSingle.key = '/api/bo/origins/{id}'

export const originsGetAll = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationOriginDataObject[]>> => {
  return Http.getRequest(
    originsGetAll.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
originsGetAll.key = '/api/bo/origins'

export const originsGetAvailable = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationOriginDataObject[]>> => {
  return Http.getRequest(
    originsGetAvailable.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
originsGetAvailable.key = '/api/bo/origins/available'

export const originsUpdateSingle = (
  id: string,
  requestBody: OrganizationOriginUpdateRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(originsUpdateSingle.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
originsUpdateSingle.key = '/api/bo/origins/{id}'

export const proxyUnsplashGetPhotos = (
  queryParams: ProxyUnsplashGetPhotosQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string>> => {
  return Http.getRequest(
    proxyUnsplashGetPhotos.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
proxyUnsplashGetPhotos.key = '/api/bo/proxies/unsplash/photos'

export const proxyUnsplashTriggerDownload = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string>> => {
  return Http.getRequest(
    template(proxyUnsplashTriggerDownload.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
proxyUnsplashTriggerDownload.key =
  '/api/bo/proxies/unsplash/photos/{id}/download'

export const sequencePollAwinPublisherShareSubmit = (
  id: string,
  requestBody: ExperiencePublisherShareRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperiencePublisherShareValidationResult>> => {
  return Http.postRequest(
    template(sequencePollAwinPublisherShareSubmit.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollAwinPublisherShareSubmit.key =
  '/api/bo/sequencepolls/{id}/awinPublisherShareSubmit'

export const sequencePollAwinPublisherShareValidate = (
  id: string,
  requestBody: ExperiencePublisherShareRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperiencePublisherShareValidationResult>> => {
  return Http.postRequest(
    template(sequencePollAwinPublisherShareValidate.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollAwinPublisherShareValidate.key =
  '/api/bo/sequencepolls/{id}/awinPublisherShareValidate'

export const sequencePollChangeAccessType = (
  id: string,
  accessType: 'Standard' | 'Extended',
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollChangeAccessType.key, { id, accessType }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollChangeAccessType.key =
  '/api/bo/sequencepolls/{id}/changeAccessType/{accessType}'

export const sequencePollGetAll = (
  queryParams: SequencePollGetAllQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListSequencePollListData>> => {
  return Http.getRequest(
    sequencePollGetAll.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetAll.key = '/api/bo/sequencepolls/list'

export const sequencePollGetAvailableApprovalStatuses = (
  queryParams: SequencePollGetAvailableApprovalStatusesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceAvailableApprovalStatus[]>> => {
  return Http.getRequest(
    sequencePollGetAvailableApprovalStatuses.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetAvailableApprovalStatuses.key =
  '/api/bo/sequencepolls/availableApprovalStatuses'

export const sequencePollGetAvailableImageObjectFocusTypes = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<PairMediaStorageItemObjectAggregatedTypeString[]>
> => {
  return Http.getRequest(
    sequencePollGetAvailableImageObjectFocusTypes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetAvailableImageObjectFocusTypes.key =
  '/api/bo/sequencepolls/availableImageObjectFocusTypes'

export const sequencePollGetAvailableOrigins = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    sequencePollGetAvailableOrigins.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetAvailableOrigins.key = '/api/bo/sequencepolls/availableOrigins'

export const sequencePollGetAvailableOwners = (
  queryParams: SequencePollGetAvailableOwnersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceAvailableOwnerData[]>> => {
  return Http.getRequest(
    sequencePollGetAvailableOwners.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetAvailableOwners.key = '/api/bo/sequencepolls/availableOwners'

export const sequencePollGetExperienceEditorConfiguration = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollExperienceEditorConfiguration>> => {
  return Http.getRequest(
    sequencePollGetExperienceEditorConfiguration.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetExperienceEditorConfiguration.key =
  '/api/bo/sequencepolls/experienceEditorConfiguration'

export const sequencePollGetFollowerShareMediaAssets = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceFollowerShareMediaAssetsData>> => {
  return Http.getRequest(
    template(sequencePollGetFollowerShareMediaAssets.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetFollowerShareMediaAssets.key =
  '/api/bo/sequencepolls/{id}/followerShareMediaAssets'

export const sequencePollGetForUpdate = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollUpdateRequest>> => {
  return Http.getRequest(
    template(sequencePollGetForUpdate.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetForUpdate.key = '/api/bo/sequencepolls/{sequencePollId}'

export const sequencePollGetOverlayData = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollOverlayData>> => {
  return Http.getRequest(
    template(sequencePollGetOverlayData.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetOverlayData.key =
  '/api/bo/sequencepolls/{sequencePollId}/overlay'

export const sequencePollGetPreview = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollPreviewData>> => {
  return Http.getRequest(
    template(sequencePollGetPreview.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetPreview.key = '/api/bo/sequencepolls/{sequencePollId}/preview'

export const sequencePollGetPublisherShareMediaAssets = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperiencePublisherShareMediaAssetsData>> => {
  return Http.getRequest(
    template(sequencePollGetPublisherShareMediaAssets.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetPublisherShareMediaAssets.key =
  '/api/bo/sequencepolls/{id}/publisherShareMediaAssets'

export const sequencePollGetTemplateForUpdate = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollUpdateRequest>> => {
  return Http.getRequest(
    template(sequencePollGetTemplateForUpdate.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetTemplateForUpdate.key = '/api/bo/sequencepolls/templates/{id}'

export const sequencePollGetTemplates = (
  queryParams?: SequencePollGetTemplatesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<NumberedListSequencePollListData>> => {
  return Http.getRequest(
    sequencePollGetTemplates.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollGetTemplates.key = '/api/bo/sequencepolls/templates'

export const sequencePollSaveUpdate = (
  requestBody: SequencePollUpdateRequest,
  queryParams?: SequencePollSaveUpdateQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SequencePollPreviewData>> => {
  return Http.postRequest(
    sequencePollSaveUpdate.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSaveUpdate.key = '/api/bo/sequencepolls/update'

export const sequencePollSetActive = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetActive.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetActive.key = '/api/bo/sequencepolls/{sequencePollId}/setActive'

export const sequencePollSetArchived = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetArchived.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetArchived.key =
  '/api/bo/sequencepolls/{sequencePollId}/setArchived'

export const sequencePollSetDeleted = (
  sequencePollId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetDeleted.key, { sequencePollId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetDeleted.key = '/api/bo/sequencepolls/{sequencePollId}/setDeleted'

export const sequencePollSetFeaturedListImage = (
  sequencePollId: string,
  requestBody: SequencePollSetFeaturedListImageRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetFeaturedListImage.key, { sequencePollId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetFeaturedListImage.key =
  '/api/bo/sequencepolls/{sequencePollId}/brandFeaturedImage'

export const sequencePollSetFlag = (
  sequencePollId: string,
  flag: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetFlag.key, { sequencePollId, flag }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetFlag.key = '/api/bo/sequencepolls/{sequencePollId}/{flag}/set'

export const sequencePollSetLiveChatEnabled = (
  experienceId: string,
  enabled: boolean,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollSetLiveChatEnabled.key, { experienceId, enabled }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollSetLiveChatEnabled.key =
  '/api/bo/sequencepolls/{experienceId}/setLiveChatEnabled/{enabled}'

export const sequencePollUnsetFlag = (
  sequencePollId: string,
  flag: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(sequencePollUnsetFlag.key, { sequencePollId, flag }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollUnsetFlag.key =
  '/api/bo/sequencepolls/{sequencePollId}/{flag}/unset'

export const sequencePollUpdateFollowerShareMediaAssets = (
  id: string,
  requestBody: ExperienceFollowerShareMediaAssetsDataRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperienceFollowerShareMediaAssetsData>> => {
  return Http.postRequest(
    template(sequencePollUpdateFollowerShareMediaAssets.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollUpdateFollowerShareMediaAssets.key =
  '/api/bo/sequencepolls/{id}/followerShareMediaAssets'

export const sequencePollUpdatePublisherShareMediaAssets = (
  id: string,
  requestBody: ExperiencePublisherShareMediaAssetsDataRequest,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExperiencePublisherShareMediaAssetsData>> => {
  return Http.postRequest(
    template(sequencePollUpdatePublisherShareMediaAssets.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollUpdatePublisherShareMediaAssets.key =
  '/api/bo/sequencepolls/{id}/publisherShareMediaAssets'

export const sequencePollValidateExternalUrl = (
  requestBody: ValidateExternalUrlRequest,
  queryParams?: SequencePollValidateExternalUrlQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ValidateExternalUrlResponse>> => {
  return Http.postRequest(
    sequencePollValidateExternalUrl.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
sequencePollValidateExternalUrl.key =
  '/api/bo/sequencepolls/validateExternalUrl'

export const uiGetConstants = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UIConstants>> => {
  return Http.getRequest(
    uiGetConstants.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
uiGetConstants.key = '/api/bo/ui/constants'

export const uiGetPermissions = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UIPermissions>> => {
  return Http.getRequest(
    uiGetPermissions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
uiGetPermissions.key = '/api/bo/ui/permissions'

export const userAcceptInvitation = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(userAcceptInvitation.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userAcceptInvitation.key = '/api/bo/users/invitations/{id}/accept'

export const userCreateAdditionalOrganization = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    userCreateAdditionalOrganization.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userCreateAdditionalOrganization.key =
  '/api/bo/users/createAdditionalOrganization'

export const userDeclineInvitation = (
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(userDeclineInvitation.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userDeclineInvitation.key = '/api/bo/users/invitations/{id}/decline'

export const userGetActiveOrganization = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationData>> => {
  return Http.getRequest(
    userGetActiveOrganization.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetActiveOrganization.key = '/api/bo/users/activeOrganization'

export const userGetAvailableOrganizations = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationSwitchable[]>> => {
  return Http.getRequest(
    userGetAvailableOrganizations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetAvailableOrganizations.key = '/api/bo/users/availableOrganizations'

export const userGetInvitations = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<OrganizationPendingInvitation[]>> => {
  return Http.getRequest(
    userGetInvitations.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetInvitations.key = '/api/bo/users/invitations'

export const userGetProfile = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UserData>> => {
  return Http.getRequest(
    userGetProfile.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userGetProfile.key = '/api/bo/users/profile'

export const userSwitchOrganization = (
  organizationId: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    template(userSwitchOrganization.key, { organizationId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userSwitchOrganization.key = '/api/bo/users/switch/{organizationId}'

export const userUpdateProfile = (
  requestBody: UserData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ [x: string]: string }>> => {
  return Http.postRequest(
    userUpdateProfile.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  )
}

/** Key is end point string without base url */
userUpdateProfile.key = '/api/bo/users/profile'
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
}
export const _CONSTANT1 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}
