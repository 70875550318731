import React, {useState} from 'react'
import SortableTree, {
  addNodeUnderParent,
  getNodeAtPath,
  removeNodeAtPath,
} from 'react-sortable-tree'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import AddItemIcon from '../../assets/Create.svg'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RemoveItemIcon from '../../assets/Delete.svg'
import DuplicateItemIcon from '../../assets/Duplicate.svg'
import styles from './SequenceBox.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  CardTreeType,
  CardType,
  changeCardTreeType,
  duplicateTreeItem,
  HeadingType,
  ITreeItem, setCardVisibility,
  setCurrentCard,
  setTreeItems,
} from 'src/redux/slices/sequenceSlice'
import { RootState } from 'src/redux/store'
import { changeImageProps } from 'src/redux/slices/sequenceSlice'
import { DEFAULT_MEDIA_ID, DEFAULT_MEDIA_URL, ALLOWED_EXPERIENCE_CARDS } from 'src/constants'
import noImage from 'src/assets/image_holder_x.jpg'
import GalleryPicker from '../Gallery/GalleryPicker'
import {Box, Checkbox, FormControlLabel, Typography} from "@mui/material";
import {autopilotLiveFocus} from "../../api/services";
import {InteractiveModeLegend} from "./InteractiveModeLegend";

const SequenceBox: React.FC<{
  mode: "Editor" | "Interactive",
  experienceId?: string,
}> = ({
  mode,
  experienceId,
}) => {
  const dispatch = useDispatch()


  const canAdd= mode === "Editor"
  const canFocus = mode === "Interactive"
  const canRemove = mode === "Editor"
  const canDuplicate = mode === "Editor"
  const canSetVisibility = mode === "Editor"
  const canDragDrop = mode === "Editor"

  const data = useSelector(
    (state: RootState) => state.sequenceReducer.sequenceBox.items,
  )

  const handleUpdateItem = (items: ITreeItem[]) => {
    dispatch(setTreeItems(items))
  }

  const getNodeKey = ({ treeIndex }: any) => treeIndex
  const currentCard = useSelector(
    (state: RootState) => state.sequenceReducer.currentProps.currentCard,
  )

  const handleSelectItem = (node: ITreeItem) => {
    const itemType =
      node.children?.length > 0 ? CardTreeType.parent : CardTreeType.child
    dispatch(
      setCurrentCard({
        card: {
          ...node,
          cardTreeType: itemType,
        },
      }),
    )
  }

  const checkItemsLimit = (updateItem: any, node: any) => {
    (node.children && node.children.length < ALLOWED_EXPERIENCE_CARDS) || !node.children
      ? updateItem()
      : toast.error(`You only can add ${ALLOWED_EXPERIENCE_CARDS} items for each parent`)
  }

  const handleFocus = (node: ITreeItem, successMessage: string) => {

    autopilotLiveFocus(experienceId, node.cardId, delayByLivestreamLatency).then(() => {
      toast.success(successMessage)
    })
  }

  const handleSetVisibility = (
    data: any,
    path: (number | string)[],
    node: ITreeItem,
    visibility: 'Public' | 'Private'
  ) => {

    if (currentCard != node) {
      handleSelectItem(node)
    }

    const parentNode: ITreeItem = getNodeAtPath({
      treeData: data,
      path: path.length > 1 && [...path].slice(0, -1),
      getNodeKey,
    })
    if (parentNode.node.children.length < 2) {
      toast.warning("You can't hide a single card!")
    } else {
      dispatch(setCardVisibility(visibility))
    }

  }

  const handleDuplicateItem = (
    data: any,
    path: (number | string)[],
    node: ITreeItem,
  ) => {
    const parentNode: ITreeItem = getNodeAtPath({
      treeData: data,
      path: path.length > 1 && [...path].slice(0, -1),
      getNodeKey,
    })
    dispatch(
      duplicateTreeItem({
        parentId: parentNode?.node?.cardId,
        itemId: node.cardId,
      }),
    )
  }

  const addNewNode = (
    path: (string | number)[],
    node: ITreeItem,
  ) => {
    const newData = addNodeUnderParent({
      treeData: data,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: {
        cardId: uuidv4(),
        name: 'name',
        cardTreeType: CardTreeType.child,
        cardType: {
          cardType: CardType.directLink,
        },
        heading: {
          type: HeadingType.noHeading,
          isAsPrevious: false,
        },
        headingType: 'NONE',
        media: {
          id: DEFAULT_MEDIA_ID,
          location: DEFAULT_MEDIA_URL,
        },
        renderDirectLinkProduct: true,
        productImageHint: null,
        visibility: 'Public',
      } as ITreeItem,
      addAsFirstChild: false,
    }).treeData
    checkItemsLimit(() => dispatch(setTreeItems(newData)), node)
    dispatch(
      changeCardTreeType({
        cardId: node.cardId,
        treeType: CardTreeType.parent,
        type: CardType.intermediate,
      }),
    )
  }

  const removeNode = (
    path: (string | number)[],
    data: any,
    getNodeKey: ({ treeIndex }: any) => any,
    handleUpdateItem: any,
    dispatch,
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    let tempPath = [...path].slice(0, -1)
    const parentNode = getNodeAtPath({
      treeData: data,
      path: path.length > 1 && tempPath,
      getNodeKey,
    })
    const newData = removeNodeAtPath({
      treeData: data,
      path,
      getNodeKey,
    })
    handleUpdateItem(newData)
    dispatch(setCurrentCard())
    parentNode.node.children &&
      parentNode.node.children.length === 1 &&
      dispatch(
        changeCardTreeType({
          cardId: parentNode.node.cardId,
          treeType: CardTreeType.child,
          type: CardType.directLink,
        }),
      )
    e.preventDefault()
  }

  const [delayByLivestreamLatency, setDelayByLivestreamLatency] = useState(true)

  const [galleryPickedCard, setGalleryPickedCard] = useState(null)
  const [galleryAnchor, setGalleryAnchor] = useState(null)
  const resetGalleryPicker = () => {
    setGalleryPickedCard(null)
    setGalleryAnchor(null)
  }

  const getAllChildrenIds = (item: ITreeItem): string[] => {
    let ids = [item.cardId]
    if (Array.isArray(item.children)) {
      item.children.forEach(child => {
        ids = ids.concat(getAllChildrenIds(child))
      })
    }

    return ids
  }

  const getNodeButtons = (node: ITreeItem, path: (string | number)[]) => {

    let isRoot = node?.cardType?.cardType === CardType.root
    let buttons = []

    if (canAdd) {
      buttons.push(
        <img style={{padding: "16px 6px"}}
             key={uuidv4()}
             src={AddItemIcon}
             alt="item-add"
             onClick={() => addNewNode(path, node)}
        />
      )
    }

    if (!isRoot) {

      // remove button
      if (canRemove) {
        buttons.push(
          <img style={{padding: "16px 6px"}}
               key={uuidv4()}
               src={RemoveItemIcon}
               alt="item-remove"
               onClick={e => {
                 removeNode(
                   path,
                   data,
                   getNodeKey,
                   handleUpdateItem,
                   dispatch,
                   e,
                 )
               }}
          />
        )
      }

      // duplicate button
      if (canDuplicate) {
        buttons.push(
          <img style={{padding: "16px 6px"}}
               src={DuplicateItemIcon}
               key={uuidv4()}
               onClick={() => handleDuplicateItem(data, path, node)}
               height="18px"
               alt="duplicate"
          />
        )
      }

      // visibility button
      if (canSetVisibility) {
        buttons.push(
          node.visibility === 'Public' ?
            <Box sx={{padding: "15px 2px", display: "inline-flex"}}
                 onClick={() => handleSetVisibility(data, path, node, 'Private')}>
              <VisibilityOutlinedIcon sx={{padding: "15px 5px", color: "#525252", fontSize: "20px"}}/>
            </Box>
            : <Box sx={{padding: "15px 2px", display: "inline-flex"}}
                   onClick={() => handleSetVisibility(data, path, node, 'Public')}>
              <VisibilityOffOutlinedIcon sx={{color: "#bebebe", fontSize: "20px"}}/>
              <Typography color={"error"}>!</Typography>
            </Box>
        )
      }
    }

    if (canFocus) {
      if (node.cardType?.cardType === "DIRECT_LINK") {
        buttons.push(
          <Box sx={{padding: "15px 2px", display: "inline-flex"}}
               onClick={() => handleFocus(node, "Navigated users to this card")}>
            <ArrowForwardIosIcon sx={{padding: "15px 5px", color: "#525252", fontSize: "20px"}}/>
          </Box>
        )
      } else {
        buttons.push(
          <Box sx={{padding: "15px 2px", display: "inline-flex"}}
               onClick={() => handleFocus(node, "Maximized this card for users")}>
            <FullscreenIcon sx={{padding: "15px 5px", color: "#525252", fontSize: "20px"}}/>
          </Box>
        )
      }
    }

    return (
      buttons
    )
  }

  function cutOff(text: string | undefined, len: number): string | undefined {
    if (text) {
      if (text.length > len) {
        return text.slice(0, len) + '...'
      } else {
        return text
      }
    } else {
      return text
    }
  }

  return (
    <div className={styles.sequenceBox}>
      <GalleryPicker
        onDismiss={resetGalleryPicker}
        anchor={galleryAnchor}
        type={'image/*'}
        itemReady={item => {
          dispatch(
            changeImageProps({
              cardId: galleryPickedCard.cardId,
              props: item,
            }),
          )
        }}
      />
      { mode === "Interactive" && (
        <Box sx={{ marginLeft: "12px", marginTop: "5px" }}>
          <FormControlLabel control={<Checkbox color={"primary"} checked={delayByLivestreamLatency} onChange={(e) => setDelayByLivestreamLatency(e.target.checked)}/>} label={"Sync with streaming delay"}/>
        </Box>
      )}
      <SortableTree
        className="tree"
        treeData={data}
        isVirtualized={false}
        onChange={(data: any) => handleUpdateItem(data)}
        dndType={"test"}
        shouldCopyOnOutsideDrop={false}
        canDrag={e =>
          canDragDrop && (e.node as ITreeItem)?.cardType?.cardType !== CardType.root
        }
        canDrop={e => {
          return canDragDrop && e.nextPath.length > 1 && e.nextParent?.children?.length <= ALLOWED_EXPERIENCE_CARDS
        }}
        generateNodeProps={({
                              node,
                              path,
                            }: {
          node: ITreeItem
          path: (string | number)[]
        }) => ({
          onClick: e => {
            if (e?.target?.tagName !== 'IMG' && e?.target?.tagName !== 'INPUT')
              handleSelectItem(node)
          },
          className: `${
            node.cardId === currentCard.cardId ? `nodeActive ${mode}` : mode
          }`,
          title: [
            // heading input
            <label key={uuidv4()} htmlFor={`file-${node.cardId}`}>
              <img
                onClick={e => {
                  if (node?.cardType?.cardType !== CardType.root) {
                    setGalleryPickedCard(node)
                    // @ts-ignore
                    setGalleryAnchor(e.target.parentElement.parentElement)
                  }
                }}
                src={node.media?.location || noImage}
                className={`${styles.itemImage} ${
                  node?.cardType?.cardType === CardType.root
                    ? styles.rootImage
                    : ''
                }`}
              />
            </label>,
            <p key={uuidv4()} className={styles.itemNameInput}>
              { mode === "Interactive" ? (
                <>
                  { node.transientProduct && (
                    <Box sx={{paddingLeft: "10px"}}>
                      <Typography sx={{fontSize: "11px"}}>
                        {cutOff(node.transientProduct.title, 21)}
                      </Typography>
                      <Typography sx={{fontSize: "10px"}}>
                        {cutOff(node.transientProduct.price, 16)}
                        {node.transientProduct.oldPrice && (
                          <span style={{ textDecoration: "line-through", marginLeft: "8px"}}>{node.transientProduct.oldPrice}</span>
                        )}
                      </Typography>
                    </Box>
                  )}

                </>
              ) : (
                <>
                  { cutOff(node?.heading?.titleText, 8) }
                </>
              )}

            </p>,
          ],
          buttons: getNodeButtons(node, path)
        })}
      />
      { mode === "Interactive" && (
        <>
          <InteractiveModeLegend/>
        </>
      )}
    </div>
  )
}

export default SequenceBox
