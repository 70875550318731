import {FC} from "react";
import {DashboardCharts} from "../../api/types";
import {Card, Grid} from "@mui/material";
import {PieChart} from "../Charts/PieChart";


export interface VisitorPieChartsData {
  charts: DashboardCharts,
  md: number,
  noDataText?: string,
}

export const VisitorPieCharts: FC<VisitorPieChartsData>
 = ({
  charts,
  md,
  noDataText = "Chart contains no data",
}) => {

  return (
    <Grid container spacing={1} sx={{ marginBottom: "32px" }}>
      <Grid item xs={11} md={md}>
        <Card variant={"outlined"}>
          <PieChart title={"Visitors by source"} series={charts.commonValues.visitorsBySource} noDataText={noDataText} />
        </Card>
      </Grid>
      <Grid item xs={11} md={md}>
        <Card variant={"outlined"}>
          <PieChart title={"Visitors by country"} series={charts.commonValues.visitorsByCountry} noDataText={noDataText} />
        </Card>
      </Grid>
      <Grid item xs={11} md={md}>
        <Card variant={"outlined"}>
          <PieChart title={"Visitors by device type"} series={charts.commonValues.visitorsByDeviceType} noDataText={noDataText} />
        </Card>
      </Grid>
    </Grid>
  )
}